<template>
  <div class="plenary">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <van-divider :style="dividerStyle">大会简介</van-divider>
    <div class="banner">
      <img src="../../assets/image/introduce.jpg" alt />
    </div>
    <div class="content">
      <p>
        中国罕见病高峰论坛（China Rare Disease Summit）发起于2012年，是中国罕见病领域最大规模的综合性论坛。
        论坛每年召开一届，旨在加强患者群体、医生专家、医药公司、测序机构、政府政策制定部门及患者组织等各利益相关方的交流与合作，
        通过行业专家权威观点呈现和碰撞，逐渐成为世界了解中国、中国了解世界的最佳窗口，同时在政策倡导、多方协作和国际交流等方面发挥重要作用。
        <br />
        <br />国内规模最大、更专业的综合性罕见病论坛
        <br />
        <br />国内最全面的利益相关方互动对话平台
        <br />
        <br />国内外最新的诊疗产品与服务展示平台
        <br />
        <br />国内外最新罕见病政策对话平台
        <br />
        <br />
      </p>
    </div>
    <div class="chairmanBox">
      <img class="chairman_img" :src="chairmanUrl" alt />
    </div>
    <br />
    <div class="adviserBox">
      <!-- <img class="chairman_img" src="../../assets/image/chairman.png" alt /> -->
      <div class="adviserTitle">顾问委员会成员</div>
      <div class="adviser" v-for="(item,i) in adviserList" :key="i">
        <div class="adviser_data">
          <div class="adviser_img">
            <div class="img_box">
              <img class="imgs" :src="item.image" alt />
            </div>
          </div>
          <div class="gues_info">
            <p class="adviserName">
              <span v-html="item.name"></span>&nbsp;&nbsp;
              <span>{{item.title}}</span>
            </p>
            <p class="adviserText" v-html="item.detail"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="interval_box"></div>
    <div class="adviserBox">
      <!-- <img class="chairman_img" src="../../assets/image/chairman.png" alt /> -->
      <div class="adviserTitle">筹备组成员</div>
      <div class="adviser" v-for="(item,i) in preparationList" :key="i">
        <div class="adviser_data">
          <div class="adviser_img">
            <div class="img_box">
              <img class="imgs" :src="item.image" alt />
            </div>
          </div>
          <div class="gues_info">
            <p class="adviserName">
              <span v-html="item.name"></span>&nbsp;&nbsp;
              <span>{{item.title}}</span>
            </p>
            <p class="adviserText" v-html="item.detail"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
import { getConfig,getAdviser,getPreparation } from "../../api/index.js";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 30%",
        "font-size": "16px"
      },
      chairmanUrl:"",
      adviserList: [
        // {
        //   name: "蔡伊志 Jane",
        //   position: "博士",
        //   image: require("../../assets/photo/61.jpg"),
        //   detail:
        //     "蔻德罕见病中心（CORD）高级顾问" +
        //     "<br/>" +
        //     "原药物信息协会（DIA）中国区总经理"
        // },
        // {
        //   name: "黄如方",
        //   position: "先生",
        //   image: require("../../assets/photo/5.jpg"),
        //   detail: "蔻德罕见病中心（CORD）创始人&主任"
        //   //  +
        //   // "<br/>" +
        //   // "第十届中国罕见病高峰论坛执行主席"
        // },
        // {
        //   name: "柯越海 ",
        //   position: "教授",
        //   image: require("../../assets/photo/keyuehai.png"),
        //   detail:
        //     "浙江大学医学院副院长，浙江大学科学技术研究院副院长" +
        //     "<br/>" +
        //     "浙江大学阿里研究院未来数字医疗联合研究中心副主任，教授、博导"
        // },
        // {
        //   name: "刘志红",
        //   position: "院士",
        //   image: require("../../assets/photo/1.jpg"),
        //   detail:
        //     "中国工程院院士、浙江大学医学院院长<br/>浙江大学医学中心主任、博士生导师<br/>国家肾脏疾病临床医学研究中心主任<br/>国家“973”计划项目首席科学家"
        // },
        // {
        //   name: "陆\xa0\xa0\xa0\xa0梅",
        //   position: "女士",
        //   image: require("../../assets/photo/lumei.jpg"),
        //   detail: "蔻德罕见病中心（CORD）" + "<br/>" + "合作发展部总监"
        // },
        // {
        //   name: "王建安 ",
        //   position: "教授",
        //   image: require("../../assets/photo/2.jpg"),
        //   detail:
        //     "浙江省特级专家，现任浙江大学医学院附属第二医院党委书记、心脏中心主任" +
        //     "<br/>" +
        //     "担任JACC: Asia 杂志首任主编，欧洲心脏结构瓣膜介入大会（CSI）共同主席" +
        //     "<br/>" +
        //     "国家重大科学研究计划项目（973）首席科学家"
        // },
        // {
        //   name: "王伟林",
        //   position: "教授",
        //   image: require("../../assets/photo/3.jpg"),
        //   detail:
        //     "浙江省特级专家" +
        //     "<br/>" +
        //     "现任浙江大学医学院附属第二医院院长" +
        //     "<br/>" +
        //     "肝胆胰外科学科带头人，器官移植中心主任"
        // },
        // {
        //   name: "吴志英",
        //   position: "教授",
        //   image: require("../../assets/photo/4.jpg"),
        //   detail:
        //     "浙江大学医学院附属第二医院副院长" +
        //     "<br/>" +
        //     "国家杰出青年基金获得者<br/>新世纪百千万人才工程国家级人选"
        // },
        // {
        //   name: "肖\xa0\xa0\xa0\xa0磊",
        //   position: "先生",
        //   image: require("../../assets/photo/8.jpg"),
        //   detail: "蔻德罕见病中心（CORD）高级顾问" + "<br/>" + "曙方医药患者支持副总监"
        // },
      ],
      preparationList: [
        // {
        //   name: "陈雯艾",
        //   position: "女士",
        //   image: require("../../assets/photo/11.jpg"),
        //   detail:
        //     "浙江大学医学院附属第二医院医务部副主任<br/>心内科副主任医师 "
        // },
        // {
        //   name: "陈彦利",
        //   position: "女士",
        //   image: require("../../assets/photo/chenyanli.jpg"),
        //   detail: "蔻德罕见病中心（CORD）" + "<br/>" + "办公室人事主管"
        // },
        // {
        //   name: "胡丹丽",
        //   position: "女士",
        //   image: require("../../assets/photo/hudanli.jpg"),
        //   detail: "蔻德罕见病中心（CORD）" + "<br/>" + "杭州办公室主任"
        // },
        // {
        //   name: "李中华",
        //   position: "先生",
        //   image: require("../../assets/photo/lizhonghua.jpg"),
        //   detail: "蔻德罕见病中心（CORD）" + "<br/>" + "合作发展部项目主管"
        // },
        // {
        //   name: "施水娟",
        //   position: "女士",
        //   image: require("../../assets/photo/14.jpg"),
        //   detail: "浙江大学医学院附属第二医院医务部罕见病工作人员"
        // },
        // {
        //   name: "王兆品",
        //   position: "先生",
        //   image: require("../../assets/photo/10.jpg"),
        //   detail: "浙江大学医学院党政办公室副主任"
        // },
        // {
        //   name: "闫\xa0\xa0\xa0\xa0骏",
        //   position: "先生",
        //   image: require("../../assets/photo/9.jpg"),
        //   detail: "蔻德罕见病中心（CORD）" + "<br/>" + "合作发展部项目主管"
        // },
        // {
        //   name: "杨明丽",
        //   position: "女士",
        //   image: require("../../assets/photo/12.jpg"),
        //   detail: "浙江大学医学院附属第二医院国际交流办公室主任"
        // },
        // {
        //   name: "余\xa0\xa0\xa0\xa0昊",
        //   position: "先生",
        //   image: require("../../assets/photo/15.jpg"),
        //   detail:
        //     "临床医学博士后，浙江大学医学院附属第二医院主治医师"
        // },
        // {
        //   name: "张冯江",
        //   position: "先生",
        //   image: require("../../assets/photo/13.jpg"),
        //   detail:
        //     "浙江大学医学院附属第二医院党政办副主任、临床技能培训中心主任" +
        //     "<br/>" +
        //     "中国医师协会培训质量工作委员会委员、培训评价组执行组长、国家住培基地评估专家"
        // },
      ]
    };
  },
  methods: {
    getConfig() {
      getConfig({ name: "chairman", type: "1" })
        .then(res => {
          this.chairmanUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAdviser(){
      getAdviser().then(res=>{
        this.adviserList=res.data
      }).catch(err=>{
        console.log(err)
      })
    },
    getPreparation(){
      getPreparation().then(res=>{
        this.preparationList=res.data
      }).catch(err=>{
        console.log(err)
      })
    }
  },
  created() {
    this.getConfig();
    this.getAdviser();
    this.getPreparation();
  }
};
</script>

<style lang="less" scoped>
.plenary {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: #f2986e;
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    width: 92%;
    margin: 0 4% 10px 4%;
    background-color: #693853;
    border: 1px solid #fff;
    .title {
      color: #fff;
      font-size: 16px;
    }
  }
  .banner {
    width: 90%;
    height: 180px;
    margin: 0 auto;
    background-color: #311e36;
    img {
      width: 100%;
      height: 180px;
    }
  }
  .content {
    width: 86%;
    margin: 16px auto 0 auto;
    color: #fff;
    font-size: 14px;
  }
  .chairmanBox {
    margin: 0 auto;
    width: 90%;
    .chairman_img {
      width: 100%;
    }
  }
  .interval_box {
    height: 20px;
  }
  .adviserBox {
    width: 90%;
    padding-bottom: 10px;
    .adviser_img {
      width: 100%;
    }
    // height: 220px;
    background-image: url("../../assets/image/bg.png");
    border: #fff solid 1px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    margin: 0 auto;
    padding-top: 20px;
    .adviserTitle {
      width: 50%;
      height: 27px;
      border: #ffffff solid 1px;
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      line-height: 27px;
      font-weight: 400;
    }
    .adviser {
      margin: 10px 0;
      .adviser_index {
        background-color: #995c85;
        height: 20px;
      }
      .adviser_data {
        display: flex;
        .adviser_img {
          flex: none;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          min-width: 80px;
          min-height: 80px;
          .img_box {
            background-color: transparent;
            width: 61px;
            height: 61px;
            margin: 0 auto;

            border-radius: 0 0 28px 0;
            border: #ffffff solid 1px;
            .imgs {
              margin: 3px;
              background-color: #693853;
              width: 55px;
              height: 55px;
              border-radius: 0 0 25px 0;
            }
          }
        }
        .gues_info {
          flex: none;
          width: 65%;
          margin: auto 0;
          p {
            margin: 0;
          }
          .adviserName {
            font-size: 14px;
            // height: 28px;
            // line-height: 28px;
          }
          .adviserText {
            font-size: 10px;
            line-height: 14px;
            white-space: pre-wrap;
            // margin: 0 auto;
          }
        }
      }
    }
  }
}
.footer-fill {
  height: 47px;
}
</style>